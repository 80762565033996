import React from 'react';
import footerStyles from './footer.module.scss';
import { useSiteMetadata } from './siteMetadata';

const Footer = ({ className }) => {
  return (
    <footer className={`footer ${footerStyles.fixedFooter} ${className}`}>
      <div className="content has-text-centered">
        <p>
          <strong>ФОСИ ВЪЗМОЖНОСТ</strong> by {useSiteMetadata().author}
        </p>
        <p> Copyright &copy; {new Date().getFullYear()} All Rights Reserved</p>

        <a
          target="_blank"
          href="http://www.facebook.com/Фондация-за-образование-спорт-изкуство-Възможност-905343302866174/?fref=ts"
          className="button is-large is-rounded is-facebook"
        >
          <span className="icon is-medium">
            <i className="fab fa-lg fa-facebook"></i>
          </span>
          <span>Facebook</span>
        </a>
      </div>
    </footer>
  );
};

export default Footer;

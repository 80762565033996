import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';

const LogoImg = () => {
  const { contentfulAsset } = useStaticQuery(graphql`
    query {
      contentfulAsset(contentful_id: { eq: "s1eQuhjVFc2sLFDeM7fFh" }) {
        title
        localFile {
          publicURL
        }
      }
    }
  `);
  return (
    <img
      width="100px"
      height="100px"
      alt={contentfulAsset.title}
      src={contentfulAsset.localFile.publicURL}
    />
  );
};

export default LogoImg;

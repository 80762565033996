import React, { useState } from "react"
import { Link, graphql, useStaticQuery } from "gatsby"
import headerStyles from "./header.module.scss"
import LogoImg from "./imgs/logo"
import { globalHistory as history } from "@reach/router"
import IsoImg from "./imgs/isoImg"

const Header = ({ className }) => {
  const { allSitePage } = useStaticQuery(graphql`
    query {
      # filter: { context: { tag: { nin: ["gallery", "news"] } } }
      allSitePage {
        edges {
          node {
            path
            context {
              title
              menu
              mainMenu
              number
              slug
            }
          }
        }
      }
    }
  `)
  const { location, navigate } = history
  const [isActive, setActive] = useState(false)
  const handleToggle = el => {
    setActive(!isActive)
  }
  let menus = {}

  allSitePage.edges.forEach(edge => {
    if (!edge.node.context || !edge.node.context.menu) {
      return
    } else if (!menus[edge.node.context.menu]) {
      menus[edge.node.context.menu] = {
        subMenus: [],
      }
    }
    if (edge.node.context.mainMenu) {
      menus[edge.node.context.menu]["title"] = edge.node.context.title
      menus[edge.node.context.menu]["path"] = edge.node.path
      menus[edge.node.context.menu]["menu"] = edge.node.context.menu
      menus[edge.node.context.menu]["mainMenu"] = edge.node.context.mainMenu
      menus[edge.node.context.menu]["number"] = edge.node.context.number
    } else {
      menus[edge.node.context.menu]["subMenus"].push({
        title: edge.node.context.title,
        path: edge.node.path,
        menu: edge.node.context.menu,
        mainMenu: edge.node.context.mainMenu,
        number: edge.node.context.number,
      })
    }
  })
  Object.entries(menus).forEach(m => {
    if (m[1].subMenus) {
      m[1].subMenus = m[1].subMenus.sort((a, b) => {
        return a.number - b.number
      })
    }
  })
  let get_menu = function (m) {
    if (m.subMenus.length > 0) {
      return (
        <div
          key={m.path + m.menu + m.title}
          className={`navbar-item has-dropdown is-hoverable 
          ${headerStyles.hasDropdown} `}
        >
          <Link
            to={m.path}
            className={`mx-1 navbar-link is-uppercase ${
              location.pathname.includes(m.menu) ? "isCurrent" : ""
            }`}
          >
            {m.title}
          </Link>
          <div className="navbar-dropdown ">
            {m.subMenus.map(sm => {
              return (
                <Link
                  key={sm.title + sm.menu}
                  className="navbar-item is-uppercase"
                  to={sm.path}
                >
                  {sm.title}
                </Link>
              )
            })}
          </div>
        </div>
      )
    } else {
      return (
        <Link
          key={m.title + m.path + m.menu}
          to={m.path}
          className={`mx-1 navbar-item is-uppercase  ${
            location.pathname.includes(m.menu) ? "isCurrent" : ""
          }`}
        >
          {m.title}
        </Link>
      )
    }
  }
  let sorted_menus = Object.entries(menus).sort((a, b) => {
    return a[1].number - b[1].number
  })
  return (
    <section className={`hero is-small ${className}`}>
      <div className={"hero-body " + headerStyles.paddingTop0}>
        <IsoImg className={"" + headerStyles.isoImg} />
        <div className={" container has-text-centered " + headerStyles.sep}>
          <Link to="/">
            <LogoImg />
          </Link>
          <h1
            style={{ whiteSpace: "nowrap" }}
            className="title has-text-weight-light is-size-4"
          >
            ФОСИ ВЪЗМОЖНОСТ
          </h1>
        </div>
      </div>
      <nav className="navbar" role="navigation" aria-label="main navigation">
        <a
          onClick={handleToggle}
          role="button"
          className={`navbar-burger ${isActive ? "is-active" : ""}`}
          aria-label="menu"
          aria-expanded="false"
        >
          <span aria-hidden="true"></span>
          <span aria-hidden="true"></span>
          <span aria-hidden="true"></span>
        </a>
        <div className={`navbar-menu ${isActive ? "is-active" : ""}`}>
          <div className={`navbar-start ${headerStyles.navbarStartOR}`}>
            {sorted_menus.map(m => {
              return get_menu(m[1])
            })}
          </div>
        </div>
      </nav>
    </section>
  )
}

export default Header

import React from "react";
import { Helmet } from "react-helmet";
import { useSiteMetadata } from "./siteMetadata";

const HeadEl = (props) => {
  // console.log(props);
  const { title } = useSiteMetadata();
  return (
    <Helmet>
      <title>{`${props.title ? props.title + " |" : ""}  ${title}`}</title>
    </Helmet>
  );
};
export default HeadEl;

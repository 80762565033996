import React from "react"
import { graphql, StaticQuery } from "gatsby"
import Img from "gatsby-image/withIEPolyfill"

const IsoImg = ({ className }) => (
  <StaticQuery
    query={graphql`
      query {
        contentfulAsset(contentful_id: { eq: "4LDw7x9JvWt7UpxKmie3pA" }) {
          localFile {
            childImageSharp {
              fixed(width: 200, height: 105) {
                ...GatsbyImageSharpFixed
              }
            }
          }
        }
      }
    `}
    render={data => {
      const imageData = data.contentfulAsset.localFile.childImageSharp.fixed
      return (
        <Img
          imgStyle={{ width: "200px", height: "105px", margin: "30px" }}
          style={{ display: "contents" }}
          className={className}
          durationFadeIn={200}
          fadeIn={true}
          fixed={imageData}
        />
      )
    }}
  />
)

export default IsoImg
